﻿window.mapRadar = {
    map: null,

    init: function () {
        if (mapRadar.map === null) {
            let radarCenter = mapCenter;
            let radarZoom = mapZoom;

            if (mapWeather.map !== null && overrideZoom === -1) {
                radarCenter = mapWeather.map.getCenter();
                radarZoom = mapWeather.map.getZoom();
            }

            mapRadar.map = IPT.map('map-radar', {
                center: radarCenter,
                mapLayers: [{ tileUrl: 'https://maps.infoplaza.nl/styles/weersvoorspelling-simple/{z}/{x}/{y}.png', zIndex: 10 }],
                minZoom: 2,
                maxZoom: 15,
                panning: pannable,
                zoom: radarZoom,
                zooming: zoomable,
                zoomSnap: .1
            });
        }   

        mapRadar.map.setLayerSeries(IPT.SERIES.NOWCAST.PRECIPITATION, {
            label: { hidden: false },
            timebar: { autoplay: true }
        });
    }
};

window.mapWeather = {
    mapMorning: null,
    mapAfternoon: null,
    mapEvening: null,
    mapNight: null,
    mapActual: null,
    prevLabel: null,
    map: null,
    xhr: null,

    init: function (mapId, label) {
        switch (label) {
            case 'ochtend': mapWeather.map = mapWeather.mapMorning; break;
            case 'middag': mapWeather.map = mapWeather.mapAfternoon; break;
            case 'avond': mapWeather.map = mapWeather.mapEvening; break;
            case 'nacht': mapWeather.map = mapWeather.mapNight; break;
            case 'actueel': mapWeather.map = mapWeather.mapActual; break;
            default: break;
        }

        let src = '';
        if (label === 'actueel') {
            src = '/api/meteo/' + mapType + '/map/' + mapKey.toLowerCase() + '?Winter=' + mapWinter;
        } else {
            src = '/api/meteo/' + mapType + '/map/daypart/' + mapKey.toLowerCase() + '?label=' + label + '&Winter=' + mapWinter;
        }

        if (mapWeather.map === null) {
            mapWeather.map = IPT.map(mapId, {
                minZoom: 2,
                maxZoom: 15,
                panning: pannable,
                zooming: zoomable,
                zoomSnap: .1
            });
            mapWeather.map.attributionControl.setPrefix('');

            if (mapGeoJson) {
                let geoJson = IPT.L.geoJSON(mapGeoJson, {
                    style: function (feature) {
                        return {
                            color: '#B9D8EA',
                            fillColor: '#FDFFFA',
                            fillOpacity: 1
                        };
                    }
                }).addTo(mapWeather.map);

                mapWeather.map.fitBounds(geoJson.getBounds(), {
                    padding: [10, 10]
                });
            } else {
                IPT.L.tileLayer('https://maps.infoplaza.nl/styles/weersvoorspelling-simple/{z}/{x}/{y}.png').addTo(mapWeather.map);
                mapWeather.map.setView(mapCenter, mapZoom);
            }

            mapWeather.map.on('click', function (e) {
                console.log('click', e.latlng);
            });
        }

        if (mapWeather.xhr !== null) mapWeather.xhr.abort();
        mapWeather.xhr = httpGet(src, function (data) {
            IPT.L.geoJSON(data, {
                pointToLayer: function (feature, latlng) {
                    return IPT.L.marker(latlng, {
                        icon: IPT.L.divIcon({
                            html: '<a href="' + feature.properties.url + '">' +
                                '<div class="label">' +
                                '<img src="https://assets.infoplaza.io/site/weersvoorspelling.nl/icons/SVG/' + feature.properties.wxco + '.svg" alt="" />' +
                                '<div class="temp">' + feature.properties.tttt + '&deg;</div>' +
                                '</div>' +
                                '<div class="location">' + feature.properties.name + '</div>' +
                                '</a>',
                            className: 'map-location'
                        })
                    });
                }
            }).addTo(mapWeather.map);
        });

        switch (label) {
            case 'ochtend': mapWeather.mapMorning = mapWeather.map; break;
            case 'middag': mapWeather.mapAfternoon = mapWeather.map; break;
            case 'avond': mapWeather.mapEvening = mapWeather.map; break;
            case 'nacht': mapWeather.mapNight = mapWeather.map; break;
            case 'actueel': mapWeather.mapActual = mapWeather.map; break;
            default: break;
        }
    }
};

window.mapToggle = {
    tabs: null,

    init: function () {
        mapToggle.tabs = document.querySelectorAll('.country-map .tabs .tab');

        for (let i = 0; i < mapToggle.tabs.length; i++) {
            (function () {
                let index = i;
                let tab = mapToggle.tabs[index];

                tab.addEventListener('click', function () {
                    mapToggle.mapChange(index);
                });
            }());
        }

        let activeTab = document.querySelector('.tabs .tab.active');
        let activeMap = activeTab.dataset.id;
        mapToggle.activateMap(activeMap);
    },

    mapChange: function (index) {
        let activeTab = document.querySelector('.tabs .tab.active');
        let activeMap = activeTab.dataset.id;
        let newTab = mapToggle.tabs[index];
        let newMap = newTab.dataset.id;

        activeTab.classList.remove('active');
        newTab.classList.add('active');
        document.querySelector('#' + activeMap).classList.add('hide');
        document.querySelector('#' + newMap).classList.remove('hide');

        mapToggle.activateMap(newMap);
    },

    activateMap: function (mapId) {
        if (mapId !== 'map-radar' && mapRadar.map !== null) {
            mapRadar.map.setLayerSeries(null);
        }

        if (mapId.indexOf('map-daypart-') == 0) {
            let label = mapId.replace('map-daypart-', '');
            mapWeather.init(mapId, label);
        }

        switch (mapId) {
            case 'map-country': mapWeather.init(mapId, 'actueel'); break;
            case 'map-radar': mapRadar.init(); break;
            default: break;
        }
    }
};

window.mapToggle.init();